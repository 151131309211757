.video-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .video-wrapper {
    width: 100%;
    position: relative;
    cursor: pointer;
    aspect-ratio: 16 / 9;
    margin-bottom: 20px;
  }
  

  
  .placeholder-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .placeholder-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    transition: opacity 0.5s ease-in-out;

  }
  
  .play-icon {
    position: absolute;
    font-size: 5rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease;
  }
  
  
  @media (max-width: 768px) {
    .play-icon {
      font-size: 3rem;
      width: 70px;
      height: 70px;
    }
  }

  .video-wrapper:hover .play-icon {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }

  .responsive-video {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }

  @media (max-width: 480px) {
    .video-container {
      padding: 5px;
    }
    .play-icon {
      font-size: 2.5rem;
      width: 60px;
      height: 60px;
    }
  }
  