.app-header {
  background: linear-gradient(135deg, #2c3e50, #1c2833);
  color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease-in-out;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: transform 0.3s, color 0.3s;
}

.logo a:hover {
  color: #3498db;
  transform: scale(1.05);
}

.nav-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu li {
  margin-left: 1.5rem;
  position: relative;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0.5rem 0;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.nav-menu a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -5px;
  left: 50%;
  background-color: #3498db;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-menu a:hover {
  color: #3498db;
}

.nav-menu a:hover::after {
  width: 100%;
  left: 0;
}

@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }
  
  .logo {
    margin-bottom: 1rem;
  }
  
  .nav-menu ul {
    flex-direction: column;
    align-items: center;
  }
  
  .nav-menu li {
    margin: 0.75rem 0;
  }
  
  .nav-menu a::after {
    bottom: -3px;
  }
}
