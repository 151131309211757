.action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
  }
  
  .action-button {
    flex: 1;
    background-color: #0b456c;
    color: white;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    font-family: 'Carlito', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .action-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .action-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .action-buttons {
      flex-direction: column;
    }
    
    .action-button {
      margin-bottom: 10px;
    }
  }