.app-footer {
  background: linear-gradient(135deg, #2c3e50, #1c2833);
  color: white;
  padding: 2rem 1.5rem;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  margin-bottom: 1.5rem;
  padding-right: 2rem;
  transition: transform 0.3s;
}

.footer-section:hover {
  transform: scale(1.02);
}

.footer-section h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.footer-section h3:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #3498db;
  transform: translateX(-50%);
}

.footer-section p {
  margin: 0.5rem 0;
  font-size: 0.95rem;
  opacity: 0.8;
  line-height: 1.6;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 0.5rem 0;
  font-size: 0.95rem;
  opacity: 0.8;
  transition: all 0.3s ease;
  position: relative;
}

.footer-links a:hover {
  color: #3498db;
  opacity: 1;
  transform: translateX(5px);
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.85rem;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  
  .footer-section {
    padding-right: 0;
  }
}
